import { ReactNode, useEffect, useState } from 'react';
import { cx } from 'styles';
import Navigation from '../../components/Navigation';
import { Container } from '@components/elements/Wrapper/Container';
import MainTabHeader from './components/MainTabHeader';
import styled from 'styled-components';
import { FOUNDATION_COLOR } from '@styles/constants/_colors';
import { getCookie, setCookie } from '@utils/cookie';
import { CookieKey } from '@constants/cookie';
import { useLocation } from 'react-router-dom';
import LEVEL from '@styles/constants/_levels';

interface Props {
  children: ReactNode;
  scrollDirection?: null | 'down' | 'up';
  headerContents?: ReactNode;
}

export default function MainLayout({ scrollDirection, headerContents, children }: Props) {
  const location = useLocation();
  const [showNoticeBanner, setNoticeBanner] = useState<string>(
    getCookie(CookieKey.NOTICE_BANNER) ?? '',
  );

  const isRootPage = location.pathname === '/' || location.pathname === '';

  const handleBannerClose = () => {
    setNoticeBanner('6.4.1');
    setCookie(CookieKey.NOTICE_BANNER, '6.4.1');
  };

  useEffect(() => {
    const yCoordinate = sessionStorage.getItem('yCoordinate');

    if (yCoordinate) {
      window.scrollTo(0, Number(yCoordinate));
      sessionStorage.removeItem('yCoordinate');
    }
  }, []);

  return (
    <Container>
      <NavContainer className={cx(scrollDirection)}>
        <MainTabHeader />
        <HeaderContentField>{headerContents}</HeaderContentField>
      </NavContainer>
      {children}
      {/* 사용 시 재활성화 */}
      {/*{isRootPage && showNoticeBanner !== '6.4.1' && (*/}
      {/*  <UpdateNoticeBanner*/}
      {/*    link="https://blog.naver.com/inout_diet/223501394287"*/}
      {/*    onClose={handleBannerClose}*/}
      {/*  />*/}
      {/*)}*/}
      <Navigation />
    </Container>
  );
}

const NavContainer = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 428px;
  z-index: ${LEVEL.COMMUNICATIONS_HEADER};
  transition-duration: 300ms;

  &.up {
    transform: translateY(0px);
  }
  &.down {
    transform: translateY(-68px);
  }
`;

const HeaderContentField = styled.div`
  width: 100%;
  max-width: 428px;
  z-index: ${LEVEL.COMMUNICATIONS_SUB_HEADER};
  background-color: ${FOUNDATION_COLOR.BASIC_WHITE};
`;
