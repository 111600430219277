import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import { ThemeChangeIcon } from '@assets/svgs/todayNutrients';
import ArrowDefault from '@assets/images/icon/ArrowDefault';

interface Props {
  onClick: () => void;
  children?: ReactNode;
}

const CollectionModalOpenButton = ({ onClick, children }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <ChangeIconBox>
        <ThemeChangeIcon />
      </ChangeIconBox>
      {children}
      <ArrowDefault size="medium" weight="bold" />
    </Wrapper>
  );
};

export default CollectionModalOpenButton;

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  margin-top: 22px;
  width: 100%;

  color: ${COLORS.WHITE};
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
`;

const ChangeIconBox = styled.div`
  margin-top: 4px;
`;
