import { useMemo } from 'react';
import styled from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { uniqueCartRoomItemsFilterState, uniqueCartRoomItemsState } from '@states/myRoomState';
import { PointAppleLeftIcon } from '@assets/svgs';
import { COLORS } from '@styles/constants/_colors';
import { TrashFillIcon } from '@assets/svgs/_Icons';
import NonePage from '@components/Atoms/NonePageInBox';
import CircleCheck from '@components/Atoms/CircleCheck';
import useToastModal from '@hooks/useToastModal';
import Popup from '@components/elements/Popup/Popup';
import useModal from '@hooks/useModal';
import { UniqueCartRoomItem } from '@models/myRoom';
import { myRoomDetailState } from '@states/StoreState';
import RoomItemDetailModal from '@pages/my/roomEdit/RoomItemDetailModal';
import CDNURLConvert from '@utils/CDNUrlConvert';

interface Props {
  onCheckboxAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxItemClick: (
    _: React.MouseEvent<unknown, React.MouseEvent>,
    row: UniqueCartRoomItem,
  ) => void;
  isHeaderChecked: boolean;
  selected: string[];
}

const CartItemList = ({
  onCheckboxAllClick,
  onCheckboxItemClick,
  isHeaderChecked,
  selected,
}: Props) => {
  const { openModal, closeModal } = useModal();
  const { openModal: openToast } = useToastModal();
  const [uniqueCartRoomItems, setUniqueCartRoomItems] = useRecoilState(uniqueCartRoomItemsState);
  const setProduct = useSetRecoilState(myRoomDetailState);
  const setUniqueCartRoomItemsFilter = useSetRecoilState(uniqueCartRoomItemsFilterState);

  const itemsCount = useMemo(() => uniqueCartRoomItems.length ?? 0, [uniqueCartRoomItems]);

  const onItemClick = (item: UniqueCartRoomItem) => {
    setProduct(item);
    openModal(RoomItemDetailModal, {});
  };

  const onSelectAllRemoveItems = () => {
    if (uniqueCartRoomItems.length === 0 || selected.length === 0) return;
    openModal(Popup, {
      headText: '선택한 아이템을 삭제하시겠어요?',
      subText1: '방에 배치해놓은 아이템도 사라져요',
      buttonText1: '아니요',
      buttonText2: '네. 삭제할래요',
      cancel: () => {
        closeModal(Popup);
      },
      onClose: () => {
        closeModal(Popup);
      },
      onClick: () => {
        closeModal(Popup);
        setUniqueCartRoomItemsFilter('삭제');
        setUniqueCartRoomItems((prev) =>
          prev.filter((item) => selected.some((v) => v === item.id)),
        );
        openToast({ children: <p>아이템을 삭제했어요</p> });
      },
    });
  };

  const onRemoveItem = (id: string) => {
    openModal(Popup, {
      headText: '선택한 아이템을 삭제하시겠어요?',
      subText1: '방에 배치해놓은 아이템도 사라져요',
      buttonText1: '아니요',
      buttonText2: '네. 삭제할래요',
      cancel: () => {
        closeModal(Popup);
      },
      onClose: () => {
        closeModal(Popup);
      },
      onClick: () => {
        closeModal(Popup);
        setUniqueCartRoomItemsFilter('삭제');
        setUniqueCartRoomItems((prev) => prev.filter((item) => item.id === id));
        openToast({ children: <p>아이템을 삭제했어요</p> });
      },
    });
  };

  return (
    <Wrapper>
      <Header>
        <LeftBox>
          <CircleCheck
            id="cart_all"
            onClick={onCheckboxAllClick}
            isChecked={itemsCount > 0 && isHeaderChecked}
          />
          <AllSelectText>전체 선택</AllSelectText>
        </LeftBox>
        <SelectText onClick={onSelectAllRemoveItems}>선택 삭제</SelectText>
      </Header>

      {uniqueCartRoomItems.length === 0 ? (
        <NonePageBox>
          <NonePage text="구매할 아이템이 없어요" type="normal" />
        </NonePageBox>
      ) : (
        <ListField>
          {uniqueCartRoomItems.map((item) => {
            const isItemSelected = selected && selected.includes(item.id as string);
            return (
              <Row key={item.room_item_idx} onClick={() => onItemClick(item)}>
                <ImgNameBox onClick={(e) => e.stopPropagation()}>
                  <CircleCheck
                    id={item.id as string}
                    isChecked={isItemSelected}
                    onClick={(_) => {
                      onCheckboxItemClick(_ as any, item);
                    }}
                  />
                  <ItemInfoBox onClick={() => onItemClick(item)}>
                    <Img src={CDNURLConvert(item.img_thumb)} />
                    <NamePriceBox>
                      <Name>{item.name}</Name>
                      <PriceBox>
                        {item.sale_percent !== 0 && (
                          <Percent>
                            {item.sale_percent}
                            <span>%</span>
                          </Percent>
                        )}
                        <PointAppleLeftIcon width={18} height={18} />
                        <Price>{item.sale_price}</Price>
                      </PriceBox>
                    </NamePriceBox>
                  </ItemInfoBox>
                </ImgNameBox>
                <TrashFillIcon
                  color={COLORS.PRIMITIVES_GRAY_400}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveItem(item.id);
                  }}
                />
              </Row>
            );
          })}
        </ListField>
      )}
    </Wrapper>
  );
};

export default CartItemList;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 20px;
`;

const Header = styled.div`
  width: 100%;
  min-height: 56px;
  background-color: ${COLORS.WHITE};
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

const LeftBox = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const AllSelectText = styled.p`
  color: ${COLORS.BLACK};
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const SelectText = styled.p`
  color: ${COLORS.BLACK};
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const NonePageBox = styled.div`
  width: 100%;
  padding: 130px 0px 130px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListField = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  row-gap: 20px;

  > div:last-of-type {
    margin-top: 6px;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg:nth-of-type(1) {
    transform: translate(-2px, 0px);
  }
`;

const ImgNameBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
`;

const ItemInfoBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
`;

const Img = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 10px;
  object-fit: contain;
  margin-left: 2px;
`;

const NamePriceBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  width: 100%;
`;

const PriceBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Percent = styled.p`
  color: ${COLORS.RED};
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-right: 6px;
  min-width: 37px;

  span {
    font-weight: 700;
  }
`;

const Price = styled.p`
  color: ${COLORS.BLACK};
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-left: 3px;
`;

const Name = styled.p`
  color: ${COLORS.BLACK};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
  letter-spacing: -0.5px;
  margin-right: 20px;
  width: 100%;
  max-width: calc(100vw - 194px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
