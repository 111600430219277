import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  myRoomCurrentMainCategoryState,
  myRoomFieldSizeState,
  myRoomState,
} from '@states/myRoomState';
import SubCategoryList from '../SubCategoryList';
import PurchasedItemList from './PurchasedItemList';
import LikedItemList from './LikedItemList';
import CategoryItemList from './CategoryItemList';
import { MyRoomItemsType } from '@models/myRoom';
import { StoreMyRoom } from '@models/store';
import dayjs from 'dayjs';

interface Props {
  onClose: () => void;
  ratio: number;
}

const ItemList = ({ onClose, ratio }: Props) => {
  const currentMainCategory = useRecoilValue(myRoomCurrentMainCategoryState);
  const setMyRoom = useSetRecoilState(myRoomState);
  const myRoomFieldSize = useRecoilValue(myRoomFieldSizeState);

  const addItem = (item: StoreMyRoom) => {
    if (item.type === '벽지') {
      setMyRoom((prev) => ({
        ...prev,
        wallpaper: {
          ...prev.wallpaper,
          id: 'wallpaper',
          type: item.type,
          room_item_idx: item.room_item_idx,
          name: item.name,
          img_origin: item.img_origin,
          img_thumb: item.img_thumb,
          img_details: item.img_details,
          width: item.img_origin_width,
          height: item.img_origin_height,
          purchased: item.purchased === 1,
          price: item.price,
          sale_price: item.sale_price,
          sale_percent: item.sale_percent,
          liked: item.liked,
          likes: item.likes,
        },
      }));
    } else if (item.type === '바닥') {
      setMyRoom((prev) => ({
        ...prev,
        colorFloor: item.color_floor,
        floor: {
          ...prev.floor,
          id: 'floor',
          type: item.type,
          room_item_idx: item.room_item_idx,
          name: item.name,
          img_origin: item.img_origin,
          img_thumb: item.img_thumb,
          img_details: item.img_details,
          width: item.img_origin_width,
          height: item.img_origin_height,
          purchased: item.purchased === 1,
          price: item.price,
          sale_price: item.sale_price,
          sale_percent: item.sale_percent,
          liked: item.liked,
          likes: item.likes,
        },
      }));
    } else {
      const uniqueTimestamp = dayjs().valueOf();
      const imgWidth = item.type === '말풍선' ? 189 : item.img_origin_width;
      const imgHeight = item.type === '말풍선' ? 55 : item.img_origin_height;

      const newItem: MyRoomItemsType = {
        id: `${item.name}-${uniqueTimestamp}`,
        type: item.type,
        room_item_idx: item.room_item_idx,
        name: item.name,
        x:
          item.type === '말풍선'
            ? Math.round(myRoomFieldSize.width / ratio) / 2 - imgWidth / 2
            : Math.round(myRoomFieldSize.width / ratio) / 2 - imgWidth / 3 / 2, // 중앙에 배치 (이미지 3배수 저장이라 나누기 3 필요)
        y:
          item.type === '말풍선'
            ? Math.round(myRoomFieldSize.height / ratio) / 2 - imgHeight / 2
            : Math.round(myRoomFieldSize.height / ratio) / 2 - imgHeight / 3 / 2, // 중앙에 배치 (이미지 3배수 저장이라 나누기 3 필요)
        img_origin: item.img_origin,
        img_thumb: item.img_thumb,
        img_details: item.img_details,
        width: item.img_origin_width,
        height: item.img_origin_height,
        purchased: item.purchased === 1,
        price: item.price,
        sale_price: item.sale_price,
        sale_percent: item.sale_percent,
        liked: item.liked,
        likes: item.likes,
      };

      setMyRoom((prev) => ({
        ...prev,
        items: [...prev.items, newItem],
      }));
    }

    onClose();
  };

  return (
    <Wrapper>
      <SubCategoryList />
      {currentMainCategory === '마이' && <PurchasedItemList addItem={addItem} />}
      {currentMainCategory === '좋아요' && <LikedItemList addItem={addItem} />}
      {currentMainCategory !== '마이' && currentMainCategory !== '좋아요' && (
        <CategoryItemList addItem={addItem} />
      )}
    </Wrapper>
  );
};

export default ItemList;

const Wrapper = styled.div`
  width: 100%;
  height: calc(70vh + 20px);
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  overflow-y: auto;
`;
