import { useEffect, useLayoutEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';

import CartItemList from './CartItemList';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import { ColorfulCartIcon } from '@assets/svgs/_Icons';
import { PointAppleIcon, PointAppleLeftIcon } from '@assets/svgs';
import BottomButton from '@components/BottomButton';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useGetPointQuery from '@pages/apple/hooks/useGetPointQuery';
import useModal from '@hooks/useModal';
import { uniqueCartRoomItemsFilterState, uniqueCartRoomItemsState } from '@states/myRoomState';
import useItemSelect from '@pages/my/hooks/useItemSelect';
import StorePurchaseModal from '@components/modal/StorePurchaseModal';
import PointPurchaseBottomSheet from '@components/modal/PointPurchaseBottomSheet';
import useToastModal from '@hooks/useToastModal';
import { useMutation, useQueryClient } from 'react-query';
import { postMyRoomItemListPurchase } from '@apis/myRoomApi';
import { timeZoneState } from '@pages/fasting/new/state';
import useAdBrix from '@hooks/adBrix/useAdBrix';

interface Props {
  onClose: () => void;
}

const CartRoomItemBottomSheet = ({ onClose }: Props) => {
  const { sendAdBrixData } = useAdBrix();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();
  const { openModal: openToast } = useToastModal();
  const [uniqueCartRoomItems, setUniqueCartRoomItems] = useRecoilState(uniqueCartRoomItemsState);
  const setUniqueCartRoomItemsFilter = useSetRecoilState(uniqueCartRoomItemsFilterState);
  const timezone = useRecoilValue(timeZoneState);
  const { onCheckboxAllClick, onCheckboxItemClick, isHeaderChecked, selected } = useItemSelect({
    items: uniqueCartRoomItems,
  });
  const currentTotalPrice = uniqueCartRoomItems
    .filter((item) => selected.some((id) => item.id === id))
    .reduce((acc, cur) => acc + cur.sale_price, 0);
  const { memberIdx } = useUserInfoQuery();
  const { data: pointInfo } = useGetPointQuery(memberIdx);
  const { mutate: purchaseMutate } = useMutation({
    mutationFn: postMyRoomItemListPurchase,
    onSuccess: () => {
      setUniqueCartRoomItemsFilter('구매');
      setUniqueCartRoomItems((prev) => prev.filter((item) => selected.some((v) => v === item.id)));
      const names = uniqueCartRoomItems
        .filter((item) => selected.some((v) => v === item.id))
        .map((item) => item.name);
      if (names.length > 0) {
        names.forEach((itemName) => {
          sendAdBrixData('customEvent', {
            eventKey: 'complete_purchase_dp_item',
            property: {
              item_title: itemName,
            },
          });
        });
      }

      openToast({ children: <p>상품 구매를 완료했어요</p> });
      queryClient.invalidateQueries({ queryKey: ['point'], exact: false });
      closeModal(StorePurchaseModal);
    },
    onError: () => {
      openToast({ children: <p>상품 구매에 실패했어요</p> });
      closeModal(StorePurchaseModal);
    },
  });

  const openPointPurchaseBottomSheet = (
    isShortage: boolean,
    amountRemaining: number,
    currentBalance: number,
  ) => {
    openModal(PointPurchaseBottomSheet, {
      isShortage,
      amountRemaining,
      currentBalance,
      onAdBrix: (pointType: string) => {
        sendAdBrixData('customEvent', {
          eventKey: 'complete_purchase_dp_point',
          property: {
            point_type: pointType,
          },
        });
      },
    });
  };

  const onClickCharge = () => {
    openPointPurchaseBottomSheet(false, 0, 0);
  };

  // 구매하시겠어요? 모달
  const onClickPurchaseItem = () => {
    openModal(StorePurchaseModal, {
      description: '구매한 아이템으로 꾸민 방은 저장 가능!',
      price: currentTotalPrice,
      onConfirm: () => {
        // 사과 부족 시
        const isShortage = (pointInfo?.balance ?? 0) < currentTotalPrice;

        if (isShortage) {
          const amountRemaining = Math.abs((pointInfo?.balance ?? 0) - currentTotalPrice);
          openPointPurchaseBottomSheet(isShortage, amountRemaining, pointInfo?.balance ?? 0);
          closeModal(StorePurchaseModal);
          return;
        }

        // 아이템 구매 요청
        purchaseMutate({
          memberIdx,
          room_item_idxes: uniqueCartRoomItems
            .filter((item) => selected.some((v) => v === item.id))
            .map((item) => Number(item.room_item_idx)),
          timezone,
        });
      },
    });
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  useEffect(() => {
    sendAdBrixData('customEvent', {
      eventKey: 'view_room_dp_list',
    });
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet containerPadding="12px 0px 0px 0px" onClose={onClose}>
          <BottomSheet.Headers>
            <Padding />
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <ContentField>
              <PointField>
                <ColorfulCartIcon width={60} height={60} />
                <Desc>
                  미보유 아이템이 있으면
                  <br />
                  저장할 수 없어요
                </Desc>
                <Row>
                  <PointAppleLeftIcon width={18} height={18} />
                  <Text marginLeft={1}>보유</Text>
                  <Text>{pointInfo?.balance ?? 0}</Text>
                  <ChargeButton onClick={onClickCharge}>
                    <p>충전</p>
                  </ChargeButton>
                </Row>
              </PointField>
              <CartItemList
                onCheckboxAllClick={onCheckboxAllClick}
                onCheckboxItemClick={onCheckboxItemClick}
                isHeaderChecked={isHeaderChecked}
                selected={selected}
              />
            </ContentField>
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <Footer>
              <TotalBox>
                <TotalText>합계</TotalText>
                <PointAppleIcon width={24} height={24} />
                <Total>{currentTotalPrice}</Total>
              </TotalBox>
              <ButtonBox>
                <BottomButton
                  color="black"
                  isDisabled={uniqueCartRoomItems.length === 0}
                  onClickEvent={onClickPurchaseItem}
                >
                  구매하기
                </BottomButton>
              </ButtonBox>
            </Footer>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default CartRoomItemBottomSheet;

const ContentField = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
`;

const Padding = styled.div`
  position: sticky;
  top: 0px;
  z-index: 10;
  width: 100%;
  min-height: 12px;
  background-color: ${COLORS.WHITE};
  border-radius: 120px;
`;

const PointField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

const Desc = styled.p`
  margin-top: 14px;
  margin-bottom: 14px;
  color: ${COLORS.BLACK};
  text-align: center;
  font-family: 'Cafe24 Ssurround';
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.5px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3px;
  margin-bottom: 13px;
`;

const Text = styled.p<{ marginLeft?: number }>`
  color: ${COLORS.BLACK};
  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)}px;
  transform: translateY(1px);
`;

const ChargeButton = styled.button`
  display: flex;
  padding: 7px 9px;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  background: ${COLORS.PRIMITIVES_GRAY_60};
  margin-left: 9px;

  p {
    color: ${COLORS.RED};
    font-family: 'Cafe24 Ssurround';
    font-size: 12px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 78px;
  background-color: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 20px;
`;

const ButtonBox = styled.div`
  min-width: 172px;
`;

const TotalBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > svg {
    transform: translateY(-1px);
  }
`;

const TotalText = styled.p`
  color: ${COLORS.BLACK};
  font-size: 15px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-right: 8px;
  white-space: nowrap;
  transform: translateY(1px);
`;

const Total = styled.p`
  color: ${COLORS.BLACK};
  text-align: center;
  font-family: Campton;
  font-size: 18px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-left: 6px;
  transform: translateY(1px);
`;
