import { createStyles, makeStyles } from '@material-ui/core';

interface Props {
  size?: 'small' | 'medium' | 'large';
  color?: string;
  weight?: 'normal' | 'bold' | 'extraBold';
}

export default function ArrowDefault({ size = 'small', color = '#fff', weight = 'normal' }: Props) {
  const classes = useStyles();

  const fragment = (() => {
    switch (size) {
      case 'small':
        return {
          icon:
            weight === 'bold' ? (
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.7998 2.1001L5.68889 5.98918L1.7998 9.87827"
                  stroke={color}
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="9"
                height="14"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.7998 2.1001L5.68889 5.98918L1.7998 9.87827"
                  stroke={color}
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
        };
      case 'medium':
        return {
          icon:
            weight === 'extraBold' ? (
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2L7.51041 8.01041L1.5 14.0208"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : weight === 'bold' ? (
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.30322 2L6.25297 6.94975L1.30322 11.8995"
                  stroke={color}
                  strokeWidth="1.7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2L6.44975 6.94975L1.5 11.8995"
                  stroke={color}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
        };
      case 'large':
        return {
          icon:
            weight === 'bold' ? (
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2L7.51041 8.01041L1.5 14.0208"
                  stroke={color}
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2L7.51041 8.01041L1.5 14.0208"
                  stroke={color}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
        };
      default:
        return {
          icon: (
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.7998 2.1001L5.68889 5.98918L1.7998 9.87827"
                stroke={color}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}

const useStyles = makeStyles(() => createStyles({}));
