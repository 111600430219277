import { getExerciseWalks, postExerciseWalks } from '@apis/exercise';
import RefreshArrow from '@assets/svgs/_Icons/arrow/RefreshArrow';
import { WidgetKind } from '@constants/widget';
import useHealthExceptionCase from '@hooks/health/useHealthExceptionCase';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import useToastModal from '@hooks/useToastModal';
import { deviceOs } from '@modules/platformCheck';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { COLORS } from '@styles/constants/_colors';
import SpinnerInBox from 'components/SpinnerInBox';
import useCount from 'pages/main/hooks/useCount';
import usePostMessageReceiver from 'pages/main/hooks/usePostMessageReceiver';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { dateState } from 'states/DateState';
import { ShoesIllustWrapper } from '../walkingInterlock/styles';
import { Container, Progress, ProgressBar } from './styles';
import styled from 'styled-components';
import useGetWeightQuery from '@pages/exercise/hooks/useGetWeightQuery';
import useModal from '@hooks/useModal';
import WalkingModal from './WalkingModal';
import WalkingResultModal from './WalkingResultModal';
import useAdBrix from '@hooks/adBrix/useAdBrix';

const SHOES_UNDER_100PERCENT_URL =
  'https://dw543otwhx7wx.cloudfront.net/main/main4-walkingShoesWithGrass.png';

const SHOES_OVER_100PERCENT_URL =
  'https://dw543otwhx7wx.cloudfront.net/main/main4-walkingShoesCongratulation.png';

const WalkingDetail = () => {
  const { sendAdBrixData } = useAdBrix();

  const FINAL_VALUE = 10000;
  const widgetKinds = [WidgetKind.DAILY_HEALTH_LOG, WidgetKind.DASHBOARD];
  const [walk, setWalk] = useState(0);
  const date = useRecoilValue(dateState);
  const { openModal, closeModal } = useModal();
  const { openModal: openToastModal } = useToastModal();
  const {
    onAndroidHealthNotionPage,
    onUnSyncHealthDataPopup,
    redirectHealthConnectPlayStore,
    checkHealthConnectModalView,
  } = useHealthExceptionCase();

  const count = useCount(walk, 1300);
  const { data: userData } = useUserInfoQuery();
  const memberIdx = useMemo(() => userData?.member_idx ?? 0, [userData?.member_idx]);
  const { data: weightData, refetch: refetchWeight } = useGetWeightQuery({
    memberIdx,
    createdAt: date,
  });

  const weight = useMemo(() => weightData?.weight ?? 0, [weightData?.weight]);

  const { isLoading } = useQuery(
    ['#walks', memberIdx, date],
    () => getExerciseWalks({ memberIdx, createdAt: date }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setWalk(res.walks || 0);
      },
    },
  );

  const { mutate } = useMutation(postExerciseWalks, {
    onSuccess: () => {
      widgetKinds.forEach((widgetKind) => {
        sendReactNativeMessage({
          type: 'widgetReloadTimelines',
          payload: { widgetKind },
        });
      });
    },
  });

  const onWalkingPostMessage = () => {
    if (deviceOs === 'android') {
      const isCheckedModal = checkHealthConnectModalView();
      if (isCheckedModal !== 'checked') {
        onAndroidHealthNotionPage();
        return;
      }
    }

    sendReactNativeMessage({
      type: 'health',
      payload: { date },
      service: 'getStep',
    });
  };

  usePostMessageReceiver((event: any) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'healthConnectUnInstall') {
      redirectHealthConnectPlayStore();
      return;
    }

    if (type === 'getStep' && data !== '') {
      setWalk(Math.round(data));
      mutate(
        { memberIdx, createdAt: date, walks: Math.round(data) },
        {
          onSuccess: async () => {
            sendAdBrixData('customEvent', {
              eventKey: `register_step_record`,
            });
            const weight = await refetchWeight();
            openModal(WalkingResultModal, {
              walk: Math.round(data),
              weight: weight.data?.weight ?? 0,
              closeModal: () => {
                closeModal(WalkingResultModal);
              },
            });
          },
        },
      );
      return;
    } else if (type === 'getStep' || type === 'iosHealthSyncFail') {
      onUnSyncHealthDataPopup();
      return;
    }
  });

  const onPercent = () => {
    return (walk / FINAL_VALUE) * 100 > 100 ? 100 : Math.floor((walk / FINAL_VALUE) * 100);
  };

  const handleOpenWalkingModal = () => {
    openModal(WalkingModal, {
      walk,
      closeModal: () => {
        closeModal(WalkingModal);
      },
      onSubmit: (value: number) => {
        setWalk(value);
        closeModal(WalkingModal);
        mutate(
          { memberIdx, createdAt: date, walks: value },
          {
            onSuccess: async () => {
              const weight = await refetchWeight();
              sendAdBrixData('customEvent', {
                eventKey: `register_step_record`,
              });
              openModal(WalkingResultModal, {
                walk: value,
                weight: weight.data?.weight ?? 0,
                closeModal: () => {
                  closeModal(WalkingResultModal);
                },
              });
            },
          },
        );
      },
      onDelete: () => {
        setWalk(0);
        mutate(
          { memberIdx, createdAt: date, walks: 0 },
          {
            onSuccess: () => {
              openToastModal({ children: <p>삭제가 완료되었어요</p> });
            },
          },
        );
        closeModal(WalkingModal);
      },
    });
  };

  if (isLoading) {
    return <SpinnerInBox height={'calc(97.6vw + 93px)'} />;
  }

  return (
    <Container>
      <TitleField>
        <b className="content">{count}</b>
        <Title>걸음</Title>
      </TitleField>

      <ShoesIllustWrapper
        src={onPercent() === 100 ? SHOES_OVER_100PERCENT_URL : SHOES_UNDER_100PERCENT_URL}
      />

      <div className="bottom">
        <dl className="info">
          <dt>
            매일 만보 걷기<span className="bang">!</span>
          </dt>
          <dd>
            <span>{onPercent()}%</span> 달성
          </dd>
        </dl>
        <Progress>
          <ProgressBar percent={onPercent()} />
        </Progress>
        <BottomField>
          <Button onClick={handleOpenWalkingModal}>{walk > 0 ? '수정하기' : '기록하기'}</Button>
          <SyncHealthAppBox onClick={onWalkingPostMessage}>
            <p>건강 앱에서 불러오기</p>
            <RefreshArrow color={COLORS.WHITE} />
          </SyncHealthAppBox>
        </BottomField>
      </div>
    </Container>
  );
};

export default WalkingDetail;

const TitleField = styled.div`
  display: flex;
  align-items: baseline;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: -0.005em;
  text-align: center;
  margin-left: 4px;
  transform: translateY(-2px);
`;

const BottomField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SyncHealthAppBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 26px;

  color: ${COLORS.WHITE};
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.615px;
`;

const Button = styled.button`
  width: 255px;
  height: 60px;
  border-radius: 120px;
  background-color: ${COLORS.PRIMITIVES_KHAKI_450};
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.6px;
  text-align: center;
  color: ${COLORS.WHITE};
  margin-top: 34px;
`;
