import { atom } from 'recoil';
import { StoreTheme } from '@models/store';
import { UniqueCartRoomItem } from '@models/myRoom';

export const themeDetailState = atom<StoreTheme>({
  key: 'ThemeDetailState',
  default: {
    theme_idx: 0,
    type: '캐릭터형',
    category: '다이어트',
    name: '',
    price: 0,
    sale_price: 0,
    sale_percent: 0,
    img_thumb: '',
    img_detail: '',
    img_character: '',
    color1: '',
    color2: '',
    color3: '',
    active: 0,
    base: 0,
    created_at: '',
    updated_at: '',
    likes: 0,
    purchases: 0,
    purchased: 0,
    liked: 0,
    new: 0,
  },
});

export const myRoomDetailState = atom<UniqueCartRoomItem>({
  key: 'myRoomDetailState',
  default: {
    id: '1',
    room_item_idx: 1,
    type: '벽지',
    name: '',
    price: 0,
    sale_price: 0,
    sale_percent: 0,
    img_thumb: 'img_thumb',
    img_origin: 'img_origin',
    img_details: [],
    width: 0,
    height: 0,
    likes: 0,
    liked: 0,
    purchased: false,
  },
});

export const myRoomDetailPageIdxState = atom<number>({
  key: 'myRoomDetailPageIdxState',
  default: 0,
});

export const themeCategoryState = atom({
  key: 'themeCategoryState',
  default: '전체',
});

export const themeLikeListState = atom<Set<number>>({
  key: 'likeThemeListState',
  default: new Set(),
});

export const themeLikeCountListState = atom<Map<number, number>>({
  key: 'themeLikeCountListState',
  default: new Map(),
});

export const themePurchasedState = atom<boolean>({
  key: 'themePurchasedState',
  default: false,
});

export const myRoomMainCategoryState = atom<string>({
  key: 'myRoomMainCategoryState',
  default: '',
});

export const myRoomSubCategoryState = atom<string>({
  key: 'myRoomSubCategoryState',
  default: '',
});

export const myRoomLikeListState = atom<Set<number>>({
  key: 'myRoomLikeListState',
  default: new Set(),
});

export const myRoomLikeCountListState = atom<Map<number, number>>({
  key: 'myRoomLikeCountListState',
  default: new Map(),
});

export const storeCategoryScrollState = atom({
  key: 'storeCategoryScrollState',
  default: {
    mainCategory: {
      theme: 0,
      myRoom: 0,
    },
    subCategoryX: 0,
  },
});
