import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { COLORS } from '@styles/constants/_colors';
import TodayNutrientsQuestionTooltip from './components/molecules/TodayNutrientsQuestionTooltip';
import SpinnerInBox from '@components/SpinnerInBox';
import CollectionModalOpenButton from '@pages/main/TodayNutrients/components/CollectionModalOpenButton';
import { HomePath } from '@constants/internalPath';
import useConfigQuery from '@hooks/queries/useConfigQuery';
import { useDate } from '@hooks/useDate';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { getCookie, setCookie } from '@utils/cookie';
import { GlitterIcon } from '@assets/svgs';
import { useNavigate } from 'react-router-dom';
import useAdBrix from '@hooks/adBrix/useAdBrix';

const CharacterTheme = lazy(() => import('@pages/main/TodayNutrients/CharacterTheme'));
const DashboardTheme = lazy(() => import('@pages/main/TodayNutrients/DashboardTheme'));

const BUBBLE_CLICK_KEY = 'isSummaryBubbleClick';

const TodayNutrients = () => {
  const { sendAdBrixData } = useAdBrix();
  const navigate = useNavigate();
  const { selectedTheme } = useConfigQuery();
  const { date } = useDate();
  const isBubbleClicked = getCookie(BUBBLE_CLICK_KEY);

  const usageTheme = () => {
    if (selectedTheme?.type === '대시보드형') return <DashboardTheme />;

    return <CharacterTheme />;
  };

  const handleCollectionModalOpen = () => {
    if (!isBubbleClicked) {
      setCookie(BUBBLE_CLICK_KEY, 'true');
    }

    sendReactNativeMessage({
      type: 'summary',
      service: 'summary',
      payload: {
        selectedDate: date,
      },
    });
  };

  const handleChangeTheme = () => {
    sendAdBrixData('customEvent', {
      eventKey: `view_themeselect`,
    });
    navigate(`${HomePath.THEME_SELECT}?category=보유 중`);
  };

  return (
    <ErrorBoundary
      fallback={
        <LoadingContainer>
          <SpinnerInBox height="493px" />
        </LoadingContainer>
      }
    >
      <Suspense
        fallback={
          <LoadingContainer>
            <SpinnerInBox height="493px" />
          </LoadingContainer>
        }
      >
        <Wrapper $themeColor={selectedTheme?.color1 ?? ''}>
          <Header>
            <TitleBox>
              <TextUnit>오늘 하루</TextUnit>
              <TodayNutrientsQuestionTooltip themeColor={selectedTheme?.color2} />
            </TitleBox>
            <SummaryBox>
              <CollectionButtonUnit
                $themeColor={selectedTheme?.color2 ?? ''}
                onClick={handleChangeTheme}
              >
                <GlitterIcon />
                테마 바꾸기
              </CollectionButtonUnit>
            </SummaryBox>
          </Header>
          {usageTheme()}
          <CollectionModalOpenButton onClick={handleCollectionModalOpen}>
            오늘 모아보기
          </CollectionModalOpenButton>
        </Wrapper>
      </Suspense>
    </ErrorBoundary>
  );
};

export default TodayNutrients;

const Wrapper = styled.div<{ $themeColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 20px 0 26px;
  background-color: ${({ $themeColor }) => $themeColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 22px;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
`;

const SummaryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

const TextUnit = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 683px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
`;

const CollectionButtonUnit = styled.div<{ $themeColor: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 13px 0px 14px;
  height: 35px;
  border-radius: 120px;

  color: ${COLORS.WHITE};
  background-color: ${({ $themeColor }) => $themeColor};

  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;

  svg {
    position: absolute;
    top: -4px;
    right: -4px;
  }
`;
