import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@mui/styles';
import Exercise from 'pages/exercise';
import MyChange from 'pages/main/MyChange';
import MyEat from 'pages/main/MyEat';
import { useRecoilValue } from 'recoil';
import ToastMsg from '../../components/elements/ToastMsg';

import AsyncBoundary from '@components/AsyncBoundary';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import useGetAdContents from '@hooks/queries/useGetAdContents';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { UserProfileForm } from '@models/profile';
import { useScrollDirectionHook } from '@modules/scrollEvent';
import { ACCESS_TOKEN, PROMOTIONKEY } from '@shared/link';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { toastMsgTextState } from '@states/globalAlert';
import { getCookie, setCookie } from '@utils/cookie';
import { lazy, useEffect, useState } from 'react';
import MainLayout from './MainLayout';
import TodayNutrients from './TodayNutrients/TodayNutrients';
import MainDateNavigation from './components/MainDateNavigation';
import { has24HoursPassed } from '@modules/date';
const MainPopup = lazy(() => import('./elements/MainPopup'));
const PromotionModal = lazy(() => import('@components/modal/PromotionModal'));

const ADBRIX_USER_PROPERTY_SYNC = 'ADBRIX_USER_PROPERTY_SYNCED';

export default function Main() {
  const classes = useStyles();
  const accessToken = getCookie(ACCESS_TOKEN);
  const token = getCookie('is_login');
  const { scrollDirection } = useScrollDirectionHook();
  const userPropertySync = getCookie(ADBRIX_USER_PROPERTY_SYNC);
  const { sendAdBrixData, sendUserProperty } = useAdBrix();

  const toastMsg = useRecoilValue(toastMsgTextState);
  const [isOpen, setIsOpen] = useState(false);

  const { data: userInfo } = useUserInfoQuery();
  const { data } = useGetAdContents('홈 배너', {
    enabled: getCookie(PROMOTIONKEY) ? has24HoursPassed(getCookie(PROMOTIONKEY) ?? '') : true,
  });

  const onCloseBanner = () => {
    setIsOpen(false);
  };

  /**
   * 목표 변경을 하지 않은 기존 유저들의 userProperty를 설정해주기 위한 로직
   */
  const syncUserProperty = () => {
    if (userPropertySync === 'synced') return;

    const adBrixUserProfileData = {
      member_age: userInfo?.member_age ?? 0,
      gender: userInfo?.gender ?? 0,
      start_weight: 0,
      weight_goal: userInfo?.weight_goal ?? 0,
      diet_mode: userInfo?.diet_mode ?? 0,
    };

    sendUserProperty(adBrixUserProfileData as UserProfileForm, userInfo?.member_idx ?? 0);
    setCookie(ADBRIX_USER_PROPERTY_SYNC, 'synced');
  };

  useEffect(() => {
    if (userInfo) {
      sendReactNativeMessage({
        type: 'maintain-login',
        payload: {
          memberIdx: userInfo?.member_idx,
          accessToken: token,
        },
      });
    }
  }, [token, userInfo]);

  useEffect(() => {
    if (accessToken) {
      try {
        (window as any).ReactNativeWebView.postMessage('config');
      } catch (err) {
        //
      }
    }

    syncUserProperty();
    sendAdBrixData('customEvent', {
      eventKey: 'view_home',
    });
  }, []);

  const toastText = (() => {
    switch (toastMsg) {
      case 0:
        return '기록이 완료되었어요';
      case 1:
        return '음식 평가가 완료되었어요';
      case 2:
        return (
          <>
            기능 이용을 위해
            <br />
            먼저 앱을 업데이트 해주세요
          </>
        );
      case 3:
        return '기록이 삭제되었어요';
      case 7:
        return '업데이트가 완료되었어요';
      case 8:
        return '데이터를 불러오지 못했어요 \n 건강 앱 접근 권한을 확인해 주세요';
      default:
        return '기록이 완료되었어요';
    }
  })();

  useEffect(() => {
    if (data && data.length > 0) {
      setIsOpen(true);
    }
  }, [data]);

  return (
    <MainLayout
      scrollDirection={scrollDirection}
      headerContents={<MainDateNavigation scrollDirection={scrollDirection} />}
    >
      <AsyncBoundary fallback={<></>}>
        <Grid className={classes.contentsWrapper}>
          {data && data.length > 0 && isOpen && (
            <PromotionModal bannerData={data.slice(0, 3)} closeBanner={onCloseBanner} />
          )}
          <MainPopup />
          <Grid className={classes.scroll}>
            <ToastMsg>{toastText}</ToastMsg>
            <TodayNutrients />
            <MyEat />
            <MyChange />
            <Exercise />
          </Grid>
        </Grid>
      </AsyncBoundary>
    </MainLayout>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    contentsWrapper: {
      paddingTop: 120,
    },
    scroll: {
      height: '100%',
      overflow: 'auto',
      marginBottom: '66px',
    },
    preventScroll: {
      position: 'fixed',
      height: '100%',
      overflow: 'hidden',
    },
  }),
);
