import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import ArrowDate from '../assets/images/icon/ArrowDate';

interface Props {
  leftClick(): void;
  rightClick(): void;
  topPosition?: number;
}

export default function DateNavigationDefaultWrapper({
  leftClick,
  rightClick,
  topPosition,
  children,
}: PropsWithChildren<Props>) {
  const classes = useStyles({ topPosition });

  return (
    <Grid
      className={classNames([classes.wrapper])}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid
        className={classes.arrowWrapper}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ArrowDate direction={'left'} color={'#000'} onClick={leftClick} />
      </Grid>
      <p>{children}</p>
      <Grid
        className={classes.arrowWrapper}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ArrowDate direction={'right'} color={'#000'} onClick={rightClick} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles<Theme, { topPosition: number | undefined }>(() =>
  createStyles({
    wrapper: {
      position: 'fixed',
      top: ({ topPosition }) => (topPosition ? topPosition : 68),
      zIndex: 999,
      width: '100%',
      maxWidth: 428,
      height: 52,
      // transition: 'all 500ms ease',
      background: '#fff',
      padding: '0 10px',
    },
    arrowWrapper: {
      width: 50,
      height: '100%',
    },
  }),
);
