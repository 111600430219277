import { getMyRoomDetail } from '@apis/myRoomApi';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { initMyRoomState, myRoomDefaultItemState, myRoomState } from '@states/myRoomState';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

const useMyRoomDetailQuery = (roomId: string, isJsonFetch: boolean) => {
  const themeIdx = roomId === '' ? undefined : Number(roomId.split('-')[1]);
  const { memberIdx } = useUserInfoQuery();

  const setMyRoom = useSetRecoilState(myRoomState);
  const setMyRoomDefaultItem = useSetRecoilState(myRoomDefaultItemState);
  const [imagesLoading, setImagesLoading] = useState<boolean[]>([]);
  const isAllQueryImageLoading = !imagesLoading.every((loading) => loading);

  // 이미지 로딩 완료 핸들러
  const handleImageLoad = (index: number) => {
    setImagesLoading((prevSources) => {
      const newSources = [...prevSources];
      newSources[index] = true; // 로딩 완료된 이미지를 true로 설정
      return newSources;
    });
  };

  // 이미지 프리로드 및 로딩 상태 설정 함수
  const preloadImages = (urls: string[]) => {
    const loadingStatusArray = Array(urls.length).fill(false);
    setImagesLoading(loadingStatusArray); // 이미지 로딩 상태 배열 설정

    urls.forEach((url, index) => {
      const img = new Image();
      img.src = url;
      img.onload = () => handleImageLoad(index); // 이미지 로드 완료 시 핸들러 호출
    });
  };

  const queryKey = ['myRoom', 'detail', memberIdx, themeIdx];

  const query = useQuery({
    queryKey,
    queryFn: () => getMyRoomDetail(memberIdx, roomId ?? ''),
    enabled: !!roomId && !isJsonFetch,
    refetchOnWindowFocus: false,
    retry: 0,
    cacheTime: 0,
    onSuccess: (data) => {
      if (data.room_wallpaper_img_origin && data.room_floor_img_origin && data.img_room_detail) {
        preloadImages([
          data.room_wallpaper_img_origin,
          data.room_floor_img_origin,
          data.img_room_detail,
        ]);
      }

      setMyRoomDefaultItem({
        room_wallpaper_idx: data.room_wallpaper_idx,
        room_wallpaper_img_origin: data.room_wallpaper_img_origin,
        room_wallpaper_name: data.room_wallpaper_name,
        room_wallpaper_img_origin_width: data.room_wallpaper_img_origin_width,
        room_wallpaper_img_origin_height: data.room_wallpaper_img_origin_height,
        room_floor_idx: data.room_floor_idx,
        room_floor_img_origin: data.room_floor_img_origin,
        room_floor_name: data.room_floor_name,
        room_floor_img_origin_width: data.room_floor_img_origin_width,
        room_floor_img_origin_height: data.room_floor_img_origin_height,
        color_floor: data.color_floor,
      });

      setMyRoom((prev) => ({
        ...prev,
        roomId: `${memberIdx}-${themeIdx}`,
        roomName: data.name,
        colorFloor: data.color_floor,
        wallpaper: {
          id: initMyRoomState.wallpaper.id,
          type: initMyRoomState.wallpaper.type,
          room_item_idx: data.room_wallpaper_idx,
          name: data.room_wallpaper_name,
          img_origin: data.room_wallpaper_img_origin,
          img_thumb: initMyRoomState.wallpaper.img_thumb,
          img_details: initMyRoomState.wallpaper.img_details,
          width: data.room_wallpaper_img_origin_width,
          height: data.room_wallpaper_img_origin_height,
          purchased: initMyRoomState.wallpaper.purchased,
          price: initMyRoomState.wallpaper.price,
          sale_price: initMyRoomState.wallpaper.sale_price,
          sale_percent: initMyRoomState.wallpaper.sale_percent,
          liked: initMyRoomState.wallpaper.liked,
          likes: initMyRoomState.wallpaper.likes,
        },
        floor: {
          id: initMyRoomState.floor.id,
          type: initMyRoomState.floor.type,
          room_item_idx: data.room_floor_idx,
          name: data.room_floor_name,
          img_origin: data.room_floor_img_origin,
          img_thumb: initMyRoomState.floor.img_thumb,
          img_details: initMyRoomState.floor.img_details,
          width: data.room_floor_img_origin_width,
          height: data.room_floor_img_origin_height,
          purchased: initMyRoomState.floor.purchased,
          price: initMyRoomState.floor.price,
          sale_price: initMyRoomState.floor.sale_price,
          sale_percent: initMyRoomState.floor.sale_percent,
          liked: initMyRoomState.floor.liked,
          likes: initMyRoomState.floor.likes,
        },
        items: [
          {
            id: initMyRoomState.items[0].id,
            type: initMyRoomState.items[0].type,
            room_item_idx: initMyRoomState.items[0].room_item_idx,
            name: initMyRoomState.items[0].name,
            x: initMyRoomState.items[0].x,
            y: initMyRoomState.items[0].y,
            img_origin: data.img_room_detail,
            img_thumb: data.img_thumb,
            img_details: initMyRoomState.items[0].img_details,
            width: initMyRoomState.items[0].width,
            height: initMyRoomState.items[0].height,
            purchased: initMyRoomState.items[0].purchased,
            price: initMyRoomState.items[0].price,
            sale_price: initMyRoomState.items[0].sale_price,
            sale_percent: initMyRoomState.items[0].sale_percent,
            liked: initMyRoomState.items[0].liked,
            likes: initMyRoomState.items[0].likes,
          },
        ],
      }));
    },
  });

  const cleanRoom = () => {
    query.refetch();
  };

  return { ...query, cleanRoom, isAllQueryImageLoading };
};

export default useMyRoomDetailQuery;
