import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import { useRecoilValue } from 'recoil';
import { myRoomDetailState } from '@states/StoreState';
import useMyRoomLike from '@pages/apple/store/hooks/useMyRoomLike';
import { SwiperProps } from 'swiper/swiper-react';
import BackArrowGnb from '@components/elements/BackArrowGnb';
import LikeCount from '@pages/apple/store/components/LikeCount';
import { PointAppleLeftIcon } from '@assets/svgs';
import purchaseNotice from '@assets/images/png/purchaseNotice.png';
import SalePrice from '@pages/apple/store/components/SalePrice';
import BottomButton from '@components/BottomButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import CDNURLConvert from '@utils/CDNUrlConvert';
import SpinnerInBox from '@components/SpinnerInBox';

interface Props {
  onPurchaseClick: () => void;
  onBackPress?: () => void;
}

const RoomItemDetailView = ({ onPurchaseClick, onBackPress }: Props) => {
  const product = useRecoilValue(myRoomDetailState);
  const [isLoaded, setIsLoaded] = useState(false);

  const { isLike, getLikeCount, onLikeClick } = useMyRoomLike();

  const swiperOptions: SwiperProps = {
    slidesPerView: 1,
    spaceBetween: 10,
    centeredSlides: true,
  };

  const getButtonText = () => {
    return product.purchased ? '보유 중이에요!' : '구매하기';
  };

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <BackArrowGnb onClick={onBackPress}>
        <LikeCount
          count={getLikeCount(product.room_item_idx)}
          active={isLike(product.room_item_idx)}
          onClick={() => onLikeClick(product.room_item_idx, product.name)}
        />
      </BackArrowGnb>

      <ContentField $isLoaded={isLoaded}>
        <Swiper {...swiperOptions} style={{ width: '100%' }}>
          {product.img_details.map((img, idx) => (
            <SwiperSlide key={idx}>
              <ThumbnailImageUnit
                src={CDNURLConvert(img)}
                alt="thumbnail"
                onLoad={() => setIsLoaded(true)}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <DescriptionBox>
          <TitleUnit>{product.name}</TitleUnit>

          <PriceBox>
            {product.sale_percent > 0 && (
              <SaleUnit>
                <span>{product.sale_percent}</span>
                <span>%</span>
              </SaleUnit>
            )}
            <PriceUnit>
              <PointAppleLeftIcon width={18} height={18} />
              <span>{product.sale_price}</span>
            </PriceUnit>
          </PriceBox>
        </DescriptionBox>

        <PurchaseNoticeImage src={purchaseNotice} />

        {!isLoaded && (
          <SpinnerBox>
            <SpinnerInBox height="100%" />
          </SpinnerBox>
        )}
      </ContentField>

      <BottomActionField>
        <SalePrice percent={product.sale_percent} price={product.sale_price} />
        <BottomButton color="black" onClickEvent={onPurchaseClick} isDisabled={product.purchased}>
          {getButtonText()}
        </BottomButton>
      </BottomActionField>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
`;

const ContentField = styled.div<{ $isLoaded: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow-y: ${({ $isLoaded }) => ($isLoaded ? 'auto' : 'hidden')};
  overflow-x: hidden;
  padding: 80px 34px 120px;
  width: 100%;
  height: 100%;
  max-width: 428px;
`;

const ThumbnailImageUnit = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
  border: 4px solid ${COLORS.WHITE};
`;

const DescriptionBox = styled.div`
  padding: 26px 0 50px;
`;

const TitleUnit = styled.div`
  font-family: 'Cafe24 Ssurround';
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.5px;
  text-align: center;
`;

const PriceBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
`;

const SaleUnit = styled.div`
  display: flex;
  gap: 1px;
  color: ${COLORS.RED};
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;

  > span:nth-child(2) {
    font-weight: 800;
  }
`;

const SpinnerBox = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100dvh - 50px - 80px);
  top: 50px;
  background-color: ${COLORS.WHITE};
`;

const PriceUnit = styled.div`
  display: flex;
  gap: 3px;
  font-size: 16px;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const PurchaseNoticeImage = styled.img`
  width: 100%;
`;

const BottomActionField = styled.div`
  display: flex;
  position: fixed;
  padding: 12px;
  max-width: 428px;
  width: 100%;
  bottom: 0;
  background-color: ${COLORS.WHITE};
`;

export default memo(RoomItemDetailView);
