import NonePage from '@components/Atoms/NonePageInBox';
import MyRoomItemListSkeleton from '@pages/my/components/skeleton/MyRoomItemListSkeleton';
import useMyRoomLikedItemListQuery from '@pages/my/hooks/useMyRoomLikedItemListQuery';
import styled from 'styled-components';
import ItemCard from './ItemCard';
import { StoreMyRoom } from '@models/store';
import useItemListScrollRestoration from '@pages/my/hooks/useItemListScrollRestoration';

interface Props {
  addItem: (item: StoreMyRoom) => void;
}

const LikedItemList = ({ addItem }: Props) => {
  const { myRoomList, isLoading, refetch } = useMyRoomLikedItemListQuery();
  const { wrapperRef } = useItemListScrollRestoration(isLoading, 'liked');

  if (isLoading) {
    return <MyRoomItemListSkeleton padding="20px 20px 0px" />;
  }

  if (!myRoomList || myRoomList?.length === 0) {
    return <NonePage text="내 위시리스트가 없어요" type="normal" />;
  }

  return (
    <Wrapper ref={wrapperRef}>
      {myRoomList?.map((item) => (
        <ItemCard
          key={`좋아요_${item.room_item_idx}`}
          onClickCard={() => addItem(item)}
          roomItemIdx={item.room_item_idx}
          name={item.name}
          thumbnail={item.img_thumb}
          salePercent={item.sale_percent}
          salePrice={item.sale_price}
          isPurchased={item.purchased === 1}
          isLikedItemList
          liked={item.liked}
          refetch={refetch}
        />
      ))}
    </Wrapper>
  );
};

export default LikedItemList;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 9.5px;
  row-gap: 30px;
  padding: 0px 20px;
  padding: 20px 20px 30px;
  overflow-y: auto;
`;
